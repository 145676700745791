import React, { useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
// import { callApi } from '../../utils/callApi';
// import { configSites, URI } from '../../config';
// import { ACTUAL, NOT_ACTUAL, templates } from './templates';
import { backlight, /*createAndOpenNewTab,*/ handleConverteQuotes, /*uuid*/ } from './EditorDocum/entities/utils';
// import { anchorIcon } from './EditorDocum/entities/consts';
import { CATEGORIES, /*categoryName*/ } from '../../utils/consts';
// import { useParams } from 'react-router-dom';
import { ConfirmModal } from '../modals';

interface EditorProps {
  editorRef?: React.MutableRefObject<any | null>;
  initialValue?: string;
  placeholder?: string;
  categoryId: CATEGORIES;
  siteId: number;
  docId?: number;
  customTemplates?: {
    title: string;
    description: string;
    content: string;
  }[];
}

// const ACCEPT =
//   '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/*';

// const findTypeFlie = (name: string) => {
//   const types = ['docx', 'doc', 'xlsx', 'xls', 'pdf'];
//   templates && types.push('pptx');
//   const fnType = types.find((tp) => tp.includes(name.substring(name.lastIndexOf('.') + 1)));
//   return fnType;
// };

export const EditorFeatured: React.FC<EditorProps> = ({
  placeholder,
  editorRef,
  initialValue,
  categoryId,
  siteId,
  docId,
  customTemplates,
}) => {
  const [showActiveQuotes, setShowActiveQuotes] = React.useState(false);
  const [isModal, /*setIsModal*/] = React.useState<null | { onaprove: (value: boolean) => void }>(null);
  // const { id } = useParams();

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    handleConverteQuotes(editorRef, setShowActiveQuotes);
  };

  const handleWindowClick = (e: any) => {
    const content = editorRef?.current?.getContent();
    const tempElement = document.createElement('html');
    tempElement.innerHTML = content || '';
    if (!showActiveQuotes && content?.includes('<mark>')) {
      const body = backlight(false, tempElement.innerHTML);
      const editor = editorRef?.current;
      if (editor) editor.setContent(body);
    } else {
      setShowActiveQuotes(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <div onClick={handleWindowClick} style={{ position: 'relative' }}>
      <ConfirmModal
        open={!!isModal}
        title='Відсутній ID документа!'
        description='Заповніть обов`язкові поля та збережіть документ!'
        confirmHandler={() => isModal?.onaprove(true)}
        closeHandler={() => isModal?.onaprove(false)}
        btnOk='Продовжити'
        btnCancel='Скасувати'
      />
      {placeholder && (
        <h3
          style={{
            margin: '0 0 5px',
            fontWeight: 400,
            lineHeight: '1.334',
            letterSpacing: '0em',
          }}
        >
          {placeholder}
        </h3>
      )}
      <div className='tox-tbtn' onClick={handleButtonClick}>
        « »
      </div>
      <Editor
        onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
        apiKey='uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv'
        initialValue={backlight(false, initialValue)}
        // init={{
        //   convert_urls: true,
        //   urlconverter_callback: function (url, node, on_save, name) {
        //     const regexName = /\b(\w*\.expertus\.com\.ua)\/law\w*\b/g;
        //     const regexInt = /^\d+/g;
        //     if (url.match(regexName)) {
        //       url = url.replace(
        //         // eslint-disable-next-line
        //         /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?/gm,
        //         ''
        //       );
        //       url = url.replace(/\/laws\//gm, '/law/');
        //     } else if (url.match(regexInt)) {
        //       url = `/law/${url}`;
        //     }
        //     return url;
        //   },
        //   content_css: '/css/index.css',
        //   target_list: [
        //     { title: 'Нова сторінка', value: '_blank' },
        //     { title: 'Та сама сторінка', value: '_self' },
        //   ],
        //   link_default_protocol: 'https',
        //   language: 'uk',
        //   height: 660,
        //   width: '900px',
        //   plugins:
        //     'powerpaste preview advcode visualblocks image link codesample table charmap hr lists checklist imagetools advtable template pageembed',
        //   menu: {
        //     scode: {
        //       title: 'Код',
        //       items: 'code',
        //     },
        //     tc: {
        //       title: 'Шаблони',
        //       items: 'template',
        //     },
        //   },
        //   menubar: 'scode tc insert format table',
        //   toolbar:
        //     'undo redo | code | styleselect | anchorbutton | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist | forecolor backcolor | charmap |  preview | imageoptions  link pageembed',
        //   paste_as_text: false,
        //   visualblocks_default_state: true,
        //   verify_html: false,
        //   powerpaste_html_import: 'merge',
        //   forced_root_block: 'p',
        //   paste_retain_style_properties: 'border-spacing,border-collapse',
        //   style_formats: [
        //     { title: 'Абзац', block: 'p' },
        //     { title: 'Заголовок', block: 'h2' },
        //     { title: 'Підзаголовок', block: 'h3' },
        //   ],
        //   templates: customTemplates ? customTemplates : templates,
        //   content_style: `
        //     .mce-content-body {
        //       min-height: 480px;
        //       max-width: 800px;
        //       margin: 0 auto;
        //       padding: 40px;
        //       font: normal 16px / 1.42857143 "Proxima Nova Rg Inner",Arial,Helvetica,sans-serif;
        //     }
        //     .mce-item-table:not([border]), .mce-item-table:not([border]) caption, .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, .mce-item-table[border="0"], .mce-item-table[border="0"] caption, .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th {
        //       border: solid 1px black;   
        //     }
        //     td {
        //       vertical-align: initial;
        //     }
        //     a {
        //       color: #0047b3;
        //       text-decoration: none;
        //       cursor: pointer;
        //       transition: color .3s,border-color .3s;
        //     }
        //     h2,h3 {
        //       font-weight: 600;
        //       letter-spacing: -0.005em;
        //       color: #252525;   
        //     }
        //     h1,h2,h3,h4,h5,p,div {
        //       position: relative;
        //     }
        //     li::marker {
        //       display: inline-block;
        //       vertical-align: baseline;
        //       min-width: 18px;
        //       margin-left: -18px;
        //       font-size: 13px;
        //       line-height: 13px;
        //       content: "\\2014\\a0";
        //       position: relative;
        //     }
        //     .link-download {
        //       color: #0047b3;
        //       text-decoration: none;
        //       cursor: pointer;
        //     }
        //     .link-type-export:after {
        //       display: inline;
        //       white-space: nowrap;
        //       text-transform: uppercase;
        //       color: #fff;
        //       font-size: 10px;
        //       padding: 2px;
        //       border-left: 3px solid #fff;
        //     }
        //     .file-png:after {
        //       content: ".png";
        //       background-color: #ff9800;
        //     }
        //     .file-jpg:after {
        //       content: ".jpg";
        //       background-color: #ff9800;
        //     }
        //     .file-doc:after {
        //       content: ".doc";
        //       background-color: #0085ff;
        //     }
        //     .file-pptx:after {
        //       content: ".pptx";
        //       background-color: #dd5f1a;
        //     }
        //     .file-xls:after {
        //       content: ".xls";
        //       background-color: #2e9b00;
        //     }
        //     .file-pdf:after {
        //       content: ".pdf";
        //       background-color: #d3032afc;
        //     }
        //     .anchor:before {
        //       pointer-events: all;
        //       transform: translateY(-1px);
        //       position: absolute;
        //       left: -30px;
        //       transition: transform .3s;
        //       will-change: transform;
        //       cursor: pointer;
        //       content: url(${URI.trim()}/images/img-icon/3006691.svg);
        //     }
        //     .myCopied {
        //       border: 1px solid black;
        //       border-block-end-color: gray;
        //       border-right-color: gray;
        //       background-color: white;
        //       color: black;
        //       text-align: center;
        //       padding: 5px;
        //       position: absolute;
        //       z-index: 1;
        //       font-size: small;
        //       font-weight: 400;
        //       left: 0;
        //       top: 0;
        //     }
        //   `,
        //   paste_preprocess: (plugin: any, args: any) => {
        //     const text = args.content.replaceAll('border: 1px solid initial;', 'border: 1px solid black;');

        //     const div = document.createElement('div');
        //     div.insertAdjacentHTML('afterbegin', text);

        //     div.querySelectorAll('*').forEach((el) => {
        //       if (['div'].includes(el.nodeName.toLowerCase())) return null;
        //       if (!'table|thead|tbody|tr|td|th|img|iframe|a'.includes(el.nodeName.toLowerCase())) {
        //         while (el.attributes.length > 0) {
        //           el.removeAttribute(el.attributes[0].name);
        //         }
        //       }
        //       if (['a'].includes(el.nodeName.toLowerCase())) {
        //         if (!el.classList.contains('link-download')) {
        //           el.removeAttribute('class');
        //         }
        //         el.removeAttribute('style');
        //       } else {
        //         //@ts-ignore
        //         el?.style?.removeProperty('color');
        //         el.removeAttribute('class');
        //       }
        //     });

        //     for (let i = 0; i < div.childElementCount; i++) {
        //       const el = div.children[i];
        //       if (el && !el.classList.contains('tiny-pageembed')) {
        //         el.innerHTML = el.innerHTML.replace(/<\/?div[^>]*>/g, '');
        //         if (['div'].includes(el.nodeName.toLowerCase())) {
        //           const p = document.createElement('p');
        //           p.innerHTML = el.innerHTML;
        //           el.parentNode?.insertBefore(p, el);
        //           el.parentNode?.removeChild(el);
        //         }
        //       }
        //     }

        //     div.querySelectorAll('p').forEach((value) => value.setAttribute('id', uuid()));

        //     const node = args.target?.selection?.getNode();
        //     if (
        //       ['templates-2__body-title', 'templates-2__user-name', 'templates-2__user-pos'].find((item) =>
        //         node?.classList?.contains(item)
        //       )
        //     ) {
        //       args.content = div.textContent;
        //     } else if (node?.classList?.contains('templates-2__body-text')) {
        //       div.querySelectorAll('p').forEach((value) => {
        //         value.classList.add('templates-2__body-text');
        //       });
        //       args.content = div.innerHTML;
        //     } else {
        //       args.content = div.innerHTML;
        //     }
        //   },
        //   images_upload_handler: async (blobInfo, success, failure) => {
        //     const formData = new FormData();
        //     if (blobInfo) {
        //       formData.append('file', blobInfo.blob(), blobInfo.filename());
        //       formData.append('siteId', String(siteId));
        //       formData.append('categoryId', String(categoryId));
        //       formData.append('id', id || '');
        //       const path = await callApi({
        //         method: 'post',
        //         data: formData,
        //         isFormData: true,
        //         path: '/upload/file',
        //       }).catch((err) => failure('error'));
        //       path && success(`${configSites[siteId].url}/${path}`);
        //     } else {
        //       failure('error');
        //     }
        //   },
        //   setup: (editor) => {
        //     function getNodeA() {
        //       const node1 = editor.selection.getNode().parentElement;
        //       if (node1 && node1.nodeName.toLowerCase() === 'a') {
        //         return node1;
        //       }
        //       const node2 = editor.selection.getNode();
        //       if (node2 && node2.nodeName.toLowerCase() === 'a') {
        //         return node2;
        //       }
        //       return null;
        //     }
        //     function changeStatus(status: string) {
        //       return () => {
        //         const node = editor.selection.getNode();
        //         let element = null as null | Element;
        //         if (node.parentElement?.className.includes('templates-2__header-status')) {
        //           element = node.parentElement;
        //         } else {
        //           element = node.querySelector('.templates-2__header-status');
        //         }
        //         if (element) {
        //           const node = document.createRange().createContextualFragment(status);
        //           element.replaceWith(node);
        //         }
        //       };
        //     }
        //     function redrawNumForTemplates() {
        //       editor
        //         .$('.templates-2__wrapper--example')
        //         .find('.templates-2__header-num')
        //         .each((i, node) => {
        //           node.textContent = String(i + 1);
        //         });
        //     }
        //     function redrawNumForCases() {
        //       editor
        //         .$('.templates-2__wrapper--case')
        //         .find('.templates-2__header-name')
        //         .each((i, node) => {
        //           node.textContent = `${String(i + 1)}. Кейс`;
        //         });
        //     }
        //     function changeElement(element: Element) {
        //       if (!element) return null;
        //       const parent = editor.$(element).parent().find('.templates-2__body-hidden');
        //       if (element.classList.contains('active')) {
        //         parent.hide();
        //         parent.removeAttr('data-mce-style');
        //       } else {
        //         parent.attr('style', 'display: block');
        //       }
        //       editor.$(element).toggleClass('active');
        //     }
        //     function uploadFile() {
        //       const node = getNodeA();
        //       if (!node || node.nodeName.toLowerCase() !== 'a') return null;
        //       const inputFile = document.createElement('input');
        //       inputFile.setAttribute('type', 'file');
        //       inputFile.accept = ACCEPT + (customTemplates ? ',.pptx' : '');
        //       document.body.appendChild(inputFile);
        //       inputFile.click();
        //       inputFile.onchange = (e) => {
        //         //@ts-ignore
        //         const file = e.target?.files[0];
        //         if (file) {
        //           const fd = new FormData();
        //           fd.append('siteId', String(siteId));
        //           fd.append('categoryId', String(categoryId));
        //           fd.append('id', id || '');
        //           fd.append('file', file);
        //           fd.append('isOriginalName', String(true));
        //           callApi({
        //             method: 'post',
        //             data: fd,
        //             isFormData: true,
        //             path: '/upload/file',
        //           })
        //             .then((path: string) => {
        //               node.removeAttribute('href');
        //               node.removeAttribute('data-mce-href');
        //               node.setAttribute('href', `${configSites[siteId].url}/${path}`);

        //               const type = path.substring(path.lastIndexOf('.') + 1);
        //               let mimetype = 'none';
        //               if (type.includes('pdf')) mimetype = 'pdf';
        //               if (type.includes('doc') || type.includes('word')) mimetype = 'doc';
        //               if (type.includes('xls') || type.includes('excel')) mimetype = 'xls';
        //               if (type.includes('png')) mimetype = 'png';
        //               if (type.includes('jpg') || type.includes('jpeg')) mimetype = 'jpg';
        //               if (type.includes('pptx')) mimetype = 'pptx';
        //               node.removeAttribute('class');
        //               node.setAttribute('class', `link-download link-type-export file-${mimetype}`);
        //               node.textContent = file.name.split('.')[0];
        //             })
        //             .catch((err) => console.log(err));
        //         }
        //       };
        //       inputFile.parentNode && inputFile.parentNode.removeChild(inputFile);
        //     }

        //     editor.on('BeforeSetContent', function (e) {
        //       const div = document.createElement('div');
        //       div.insertAdjacentHTML('afterbegin', e.content || '');
        //       div.querySelectorAll('*').forEach((el) => {
        //         //@ts-ignore
        //         el?.style?.removeProperty('font-size');
        //       });
        //       e.content = div.innerHTML;
        //       if (e.content?.includes('<div contenteditable="false" class="tiny-pageembed"><iframe')) {
        //         const content = e.content.replace(
        //           '<div contenteditable="false" class="tiny-pageembed"><iframe',
        //           `<div style="width: 100%;" class="tiny-pageembed"><div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"`
        //         );
        //         e.content = content + '</div>';
        //       }
        //       if (e.content === '') {
        //         e.content = `<p id="${uuid()}"><br></p>`;
        //       }

        //       const content = e.content;
        //       const tempElement = document.createElement('div');
        //       tempElement.innerHTML = content;
        //       const tdElements = tempElement.querySelectorAll('td');
        //       tdElements.forEach((tdElement) => {
        //         const pElement = tdElement.querySelector('p');
        //         if (!pElement || !pElement.id) {
        //           const tdContent = tdElement.textContent;
        //           if (tdContent !== null) {
        //             const trimmedContent = tdContent.trim();
        //             if (trimmedContent) {
        //               const pElement = document.createElement('p');
        //               pElement.id = uuid();
        //               pElement.innerHTML = tdElement.innerHTML;
        //               tdElement.innerHTML = pElement.outerHTML;
        //             }
        //           }
        //         }
        //       });
        //       // Для всіх заголків шаблону приклад , у яких немає айді додаємо .
        //       const toggleTitles = tempElement.querySelectorAll('.toggle__title');
        //       toggleTitles.forEach((titleElement) => {
        //         if (!titleElement.id) {
        //           titleElement.id = uuid();
        //         }
        //       });
        //       e.content = tempElement.innerHTML;
        //     });
        //     editor.on('SetContent', (e) => {
        //       const body = editor.getBody();
        //       Array.from(body.children).forEach((child) => {
        //         if (!child.getAttribute('id')) {
        //           child.setAttribute('id', uuid());
        //         }
        //       });
        //       redrawNumForTemplates();
        //       redrawNumForCases();
        //     });
        //     editor.on('keyup', (e) => {
        //       const elem = editor.selection.getNode();
        //       if (!elem.getAttribute('id')) {
        //         elem.setAttribute('id', uuid());
        //       }
        //     });
        //     editor.ui.registry.addIcon('anchorIcon', anchorIcon);
        //     editor.ui.registry.addButton('anchorbutton', {
        //       icon: 'anchorIcon',
        //       onAction: () => {
        //         const _body = editor.getBody();
        //         if (_body.children[0].classList.contains('anchor')) {
        //           editor.dom.removeClass(_body.childNodes as unknown as Node[], 'anchor');
        //         } else {
        //           editor.dom.addClass(_body.childNodes as unknown as Node[], 'anchor');
        //         }
        //       },
        //     });
        //     editor.ui.registry.addContextToolbar('contextformtogglebutton', {
        //       predicate: (node) => {
        //         return node.nodeName.toLowerCase() === 'a' && node.classList.contains('link-download');
        //       },
        //       items: 'uploadcare | reviewfile | downloadfile' + (customTemplates ? ' | editFileName' : ''),
        //       position: 'selection',
        //     });
        //     editor.ui.registry.addButton('uploadcare', {
        //       text: 'Завантажити',
        //       onAction: function (_) {
        //         if (id) {
        //           uploadFile();
        //         } else {
        //           setIsModal({
        //             onaprove: (value) => {
        //               value && uploadFile();
        //               setIsModal(null);
        //             },
        //           });
        //         }
        //         if (customTemplates) {
        //           const node = getNodeA();
        //           if (!node || node.nodeName.toLowerCase() !== 'a') return null;
        //           //@ts-ignore
        //           const url = node?.href as string;

        //           node.textContent = url.split('/').pop()?.split('.')[0] || '';
        //         }
        //       },
        //     });
        //     editor.ui.registry.addButton('reviewfile', {
        //       text: 'Переглянути',
        //       onAction: () => {
        //         const node = getNodeA();
        //         if (!node || node.nodeName.toLowerCase() !== 'a') return null;
        //         //@ts-ignore
        //         const url = node?.href;
        //         if (url && !!url.replace(window.location.href, '')) {
        //           const type = findTypeFlie(url);
        //           if (type === 'pdf') {
        //             createAndOpenNewTab(url);
        //           } else if (!!['docx', 'doc', 'xlsx', 'xls', 'pptx'].find((it) => it === type)) {
        //             createAndOpenNewTab(`https://view.officeapps.live.com/op/embed.aspx?src=${url}`);
        //           } else {
        //             const iframe = "<iframe width='100%' height='100%' src='" + url + "'></iframe>";
        //             const x = window.open();
        //             x?.document.write(iframe);
        //             x?.document.close();
        //           }
        //         }
        //       },
        //     });
        //     editor.ui.registry.addButton('downloadfile', {
        //       text: 'Скачати',
        //       onAction: async () => {
        //         try {
        //           const node = getNodeA();
        //           if (!node || node.nodeName.toLowerCase() !== 'a') return null;
        //           //@ts-ignore
        //           const url = node?.href as string;
        //           if (url) {
        //             const response = await fetch(url);
        //             const blobImage = await response.blob();
        //             const href = URL.createObjectURL(blobImage);
        //             const newFileName = node.getAttribute('data-new-file-name');
        //             const anchorElement = document.createElement('a');
        //             anchorElement.href = href;

        //             const filename = customTemplates ? newFileName || '' : url.split('/').pop() || '';
        //             anchorElement.download = decodeURI(filename);

        //             document.body.appendChild(anchorElement);
        //             anchorElement.click();

        //             document.body.removeChild(anchorElement);
        //             window.URL.revokeObjectURL(href);
        //           }
        //         } catch (err) {
        //           console.log(err);
        //         }
        //       },
        //     });
        //     editor.ui.registry.addButton('editFileName', {
        //       text: 'Редагувати',
        //       onAction: async () => {
        //         const node = getNodeA();

        //         if (!node || node.nodeName.toLowerCase() !== 'a') return null;
        //         //@ts-ignore
        //         const url = node?.href as string;

        //         const urlParts = url.split('/');
        //         let fileNameWithExtension = urlParts[urlParts.length - 1];

        //         // Декодируем специальные символы, такие как пробелы
        //         fileNameWithExtension = decodeURIComponent(fileNameWithExtension);

        //         const questionMarkIndex = fileNameWithExtension.lastIndexOf('?');
        //         const fileName = fileNameWithExtension
        //           .substring(0, questionMarkIndex !== -1 ? questionMarkIndex : undefined)
        //           .split('.')[0];

        //         if (url) {
        //           const newFileName = window.prompt('Введіть назву:', fileName);

        //           if (newFileName !== null) {
        //             node.setAttribute('data-new-file-name', decodeURI(`${newFileName}.pptx`));
        //             node.textContent = newFileName;
        //           }
        //         }
        //       },
        //     });

        //     editor.ui.registry.addContextToolbar('contextformtogglebuttonactualstatus', {
        //       predicate: (node) => {
        //         return node.classList.contains('templates-2__header-status');
        //       },
        //       items: 'actualstatus | notactualstatus',
        //       position: 'selection',
        //     });
        //     editor.ui.registry.addButton('actualstatus', {
        //       text: 'Актуально',
        //       onAction: changeStatus(ACTUAL),
        //     });
        //     editor.ui.registry.addButton('notactualstatus', {
        //       text: 'На актуалізаціі',
        //       onAction: changeStatus(NOT_ACTUAL),
        //     });
        //     editor.on('click', (node) => {
        //       if (
        //         node.target?.classList?.contains('templates-2__body-toggle-icon') &&
        //         node.target?.parentElement?.classList?.contains('templates-2__body-toggle')
        //       ) {
        //         changeElement(node.target?.parentElement);
        //       }
        //       if (
        //         node.target?.parentElement?.classList?.contains('templates-2__body-toggle-icon') &&
        //         node.target?.parentElement?.parentElement?.classList?.contains('templates-2__body-toggle')
        //       ) {
        //         changeElement(node.target?.parentElement?.parentElement);
        //       }
        //       if (
        //         node.x > 500 &&
        //         ['toggle', 'templates-2__global-wrapper'].find((item) => node.target?.classList.contains(item))
        //       ) {
        //         node.target.remove();
        //         redrawNumForTemplates();
        //         redrawNumForCases();
        //       }
        //       if (node.target?.classList?.contains('anchor') && node.x < 37 && docId) {
        //         const id = node.target.id || editor.selection.getNode().id;
        //         navigator.clipboard.writeText(`${configSites[siteId].url}/${categoryName[categoryId]}/${docId}#${id}`);
        //         const el = editor.dom.create('span', { class: 'myCopied' }, 'Скопійовано');
        //         node.target.append(el);
        //         let timeout = setTimeout(() => {
        //           el.remove();
        //           clearTimeout(timeout);
        //         }, 1000);
        //       }
        //     });

        //     editor.on('keydown', (node) => {
        //       const elem = editor.selection.getNode();
        //       const renge = editor.selection.getRng();
        //       if (elem && elem.classList) {
        //         if (
        //           elem.className === 'templates-2__header-icon-name' ||
        //           elem.classList.contains('templates-2__header-num') ||
        //           elem.classList.contains('templates-2__header-name') ||
        //           elem.parentElement?.classList.contains('templates-2__header-status')
        //         ) {
        //           node.preventDefault();
        //           return false;
        //         }
        //         if (node.key === 'Enter') {
        //           if (
        //             [
        //               'toggle__subtitle',
        //               'toggle__title',
        //               'templates-2__body-title',
        //               'templates-2__header-name',
        //               'templates-2__user-name',
        //               'templates-2__user-pos',
        //             ].find((item) => elem.classList.contains(item))
        //           ) {
        //             node.preventDefault();
        //             return false;
        //           }
        //         }

        //         if (node.key === 'Delete' || node.key === 'Backspace') {
        //           try {
        //             if (
        //               [
        //                 'toggle__subtitle',
        //                 'toggle__title',
        //                 'templates-2__body-title',
        //                 'templates-2__header-name',
        //                 'templates-2__user-name',
        //                 'templates-2__user-pos',
        //               ].find((item) => elem.classList.contains(item))
        //             ) {
        //               if (node.key === 'Delete' || renge.endOffset === 0) {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //               if (elem?.textContent?.length === 0) {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //             }

        //             if (
        //               elem.parentNode &&
        //               ['toggle__body', 'templates-2__body-hidden', 'templates-2__footer'].find((item) =>
        //                 elem.parentElement?.classList.contains(item)
        //               )
        //             ) {
        //               if (node.key === 'Delete') {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //               if (elem?.parentNode?.children.length === 1 && renge.endOffset === 0) {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //               if (elem?.parentNode?.children.length === 1 && elem?.textContent?.length === 0) {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //             }

        //             if (
        //               // @ts-ignore
        //               elem?.previousSibling?.classList?.contains('toggle') ||
        //               // @ts-ignore
        //               elem?.previousSibling?.classList?.contains('templates-2__global-wrapper')
        //             ) {
        //               const legthText = elem?.textContent?.length || 0;
        //               if (legthText < 2) {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //             }

        //             if (
        //               // @ts-ignore
        //               (elem?.previousSibling?.classList?.contains('toggle') &&
        //                 // @ts-ignore
        //                 elem?.nextSibling?.classList?.contains('toggle')) ||
        //               // @ts-ignore
        //               (elem?.previousSibling?.classList?.contains('templates-2__global-wrapper') &&
        //                 // @ts-ignore
        //                 elem?.nextSibling?.classList?.contains('templates-2__global-wrapper'))
        //             ) {
        //               const legthText = elem?.textContent?.length || 0;
        //               if (legthText < 2) {
        //                 node.preventDefault();
        //                 return false;
        //               }
        //             }
        //           } catch (err) {
        //             console.log(err);
        //           }
        //         }
        //       }
        //     });

        //     editor.on('click', () => {
        //       const content = editor.getContent();
        //       if (!showActiveQuotes && content.includes('<mark>')) {
        //         editor.setContent(backlight(false, content));
        //       }
        //       if (showActiveQuotes) {
        //         setShowActiveQuotes(false);
        //       }
        //     });

        //     editor.ui.registry.addContextToolbar('textselection', {
        //       predicate: function (node) {
        //         return !editor.selection.isCollapsed();
        //       },
        //       items: 'copy cut quicklink',
        //       position: 'node',
        //     });
        //   },
        // }}
      />
    </div>
  );
};
