import React from 'react';

import { IFormFile } from '../../store/formFiles/types';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../store/formFiles/actions';
import { AppStore } from '../../store/applicationState';
import { FormFilesModal } from '../modals/FilesModal/FormFilesModal';

type SelectPopupProps = {
  docId: number;
  files: IFormFile[];
  title?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  url?: string;
  type?: number;
  handleClose?: () => void;
};

export const SelectPopupFormFile: React.FC<SelectPopupProps> = React.memo(({ title, style, files, className, docId, handleClose, url, type }) => {
  const [titleView, setTitleView] = React.useState<string>(title || 'Додатки');
  const [isFocus, setIsFocus] = React.useState(false);
  const { FormFiles } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const checkName = React.useCallback((count: number) => {
    if (count > 4) return 'файлів';
    if (count > 1) return 'файли';
    if (count === 1) return 'файл';
    return '';
  }, []);

  React.useEffect(() => {
    if (FormFiles.data) {
      const count = FormFiles.data?.filter((file) => file.type === type).length;
      const sub = checkName(count);
      setTitleView(`${title} ${count || ''} ${sub}`);
    }
  }, [FormFiles.data, title, type, checkName]);

  React.useEffect(() => {
    if (files) {
      dispatch(setData(files));
    }
  }, [dispatch, files]);

  const handleLeaveFocus = () => {
    setIsFocus(!isFocus);
  };

  // const setClass = () => {
  //   if (isFocus) return 'classes.placeholderFocus';
  // };

  return (
    <div style={style} >
      {titleView && <div>{titleView}</div>}
      <div onClick={handleLeaveFocus}>
        <div  />
      </div>
      {isFocus ? (
        <FormFilesModal
          open={isFocus}
          docId={docId}
          handleClose={() => {
            setIsFocus(false);
            handleClose && handleClose();
          }}
          urlLink={url}
          type={type}
        />
      ) : null}
    </div>
  );
});
