import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { AlertMessage } from '../AlertMessage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  minWidth: '600px',
};

interface Props {
  closeHandler?: () => void;
  confirmHandler?: (callback?: (error: string) => void) => void;
  open: boolean;
  title?: string;
  description?: string;
  btnOk?: string;
  btnCancel?: string;
}

export const ConfirmModal: React.FC<Props> = ({
  open,
  title,
  description,
  btnOk = 'Oк',
  btnCancel = 'Відміна',
  closeHandler,
  confirmHandler,
}) => {
  const [errors, setErrors] = React.useState('');

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Box p={'10px 20px'}>
          <Typography id='modal-modal-title' sx={{ textAlign: 'center' }} variant='h6' component='h2'>
            {title || ''}
          </Typography>
          <Typography sx={{ textAlign: 'center' }} component='div'>
            {description || ''}
          </Typography>
        </Box>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
        <Stack
          sx={{ background: 'rgb(243, 240, 240)', p: '10px 20px' }}
          spacing={2}
          direction={'row'}
          justifyContent={'space-between'}
        >
          {confirmHandler ? (
            <Button variant={'contained'} color={'primary'} onClick={() => confirmHandler((error) => setErrors(error))}>
              {btnOk}
            </Button>
          ) : null}
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            {btnCancel}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
