import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import IconButton from '@mui/material/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { AppStore } from '../../store/applicationState';
import {
  SearchDocs,
  TypeGetDocsR,
  setEditDoc,
  GetRedactions,
  CreateGroup,
  setSearchData,
} from '../../store/officialDoc/actions';
import { BasicDatePicker, SelectSearch } from '../ui';
import moment from 'moment';
import { RedactionsList } from './RedactionsList';
import { colorsStatus } from '../../utils/consts';
import { useSearchAny } from '../../hooks/useSearchAny';
import { setSideBar } from '../../store/sidebar/actions';

interface Column {
  id: 'id' | 'name' | 'type' | 'approved' | 'update' | 'author' | 'redactions' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  { id: 'type', label: 'Тип', align: 'center', width: '10%' },
  { id: 'approved', label: 'Статус', align: 'center', width: '10%' },
  {
    id: 'update',
    label: 'Дата змін',
    align: 'center',
    width: '10%',
  },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'redactions',
    label: 'Редакції',
    align: 'center',
    width: '13%',
  },
  {
    id: 'author',
    label: 'Створено/Редаговано',
    width: '12%',
  },
];

type PropsType = {};

export const OfficialDocList: React.FC<PropsType> = () => {
  const [groupId, setGroupId] = React.useState(0);
  const [id, setId] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [type, setType] = React.useState('');
  const [approved, setApproved] = React.useState('');
  const [dateDoc, setDateDoc] = React.useState<Date | null>(null);
  const [docNum, setDocNum] = React.useState('');
  const [error, setError] = React.useState(false);
  const { OfficialDoc, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const { loading, handleSearch } = useSearchAny({
    SearchData: SearchDocs,
  });

  React.useEffect(() => {
    if (OfficialDoc.data) {
      dispatch(setSearchData(OfficialDoc.data));
    }
  }, [dispatch, OfficialDoc.data]);

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      dispatch(setEditDoc(null));
    }
  }, [dispatch, Sidebar.page]);

  React.useEffect(() => {
    if (moment(dateDoc).isValid() || type || approved) {
      const data = {} as TypeGetDocsR['data'];
      if (dateDoc) data['docDate'] = dateDoc;
      if (type) data['typeId'] = Number(type);
      if (approved) data['approved'] = Number(approved);
      if (title) data['title'] = title;
      if (docNum) data['docNum'] = docNum;

      dispatch(
        SearchDocs.request({
          data: data,
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, type, dateDoc, approved]);

  const createDoc = () => {
    if (OfficialDoc.loading) return null;
    dispatch(
      CreateGroup.request({
        callBack: (success, id) => {
          if (!success) {
            setError(true);
            const timeId = setTimeout(() => {
              setError(false);
              clearTimeout(timeId);
            }, 1500);
          }
          if (success && !Number.isNaN(Number(id))) {
            window.open(`/document/${id}`);
          }
        },
      })
    );
  };

  if (groupId) {
    return <RedactionsList groupId={groupId} handleBack={() => setGroupId(0)} />;
  }

  return (
    <>
      <Box >
        <div style={{ position: 'relative' }}>
          <Button
            disabled={OfficialDoc.loading}
            
            variant='contained'
            color='primary'
            onClick={createDoc}
          >
            Створити документ
          </Button>
          {error ? <div >Виникла помилка</div> : ''}
        </div>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={id}
          
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            const num = event.target.value.replace(/[^0-9.]/g, '');
            setId(num);
            handleSearch(num, 'id');
          }}
        />
        <TextField
          
          size='small'
          id='outlined-basic-title'
          label='Назва'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            const data: any = {};
            if (dateDoc) data['docDate'] = dateDoc;
            if (type) data['typeId'] = Number(type);
            if (docNum) data['docNum'] = docNum;
            setTitle(event.target.value);
            handleSearch(event.target.value, 'title', data);
          }}
        />
        <SelectSearch
          style={{ width: '210px' }}
          title='Тип документа'
          value={type}
          
          options={OfficialDoc.configurations?.typesDoc.map((it) => ({ value: String(it.id), label: it.name })) || []}
          onChange={setType}
        />
        <SelectSearch
          style={{ width: '210px' }}
          title='Статус'
          value={approved}
          
          options={[
            { id: 0, name: 'Усі' },
            { id: 1, name: 'Створено' },
            { id: 2, name: 'Опублікований' },
            { id: 3, name: 'Видалений' },
          ].map((it) => ({ value: String(it.id), label: it.name }))}
          onChange={(value) => {
            setApproved(`${value}`);
          }}
        />
        <div  style={{ width: '210px' }}>
          <BasicDatePicker
            label='Дата документа'
            value={dateDoc}
            onChange={(value) => {
              setDateDoc(value);
            }}
          />
        </div>
        <TextField
          
          style={{ marginRight: '20rem' }}
          size='small'
          id='outlined-basic-title'
          label='Номер'
          variant='outlined'
          value={docNum}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            const data: any = {};
            if (dateDoc) data['docDate'] = dateDoc;
            if (type) data['typeId'] = Number(type);
            if (docNum) data['title'] = title;
            setDocNum(event.target.value);
            handleSearch(event.target.value, 'docNum', data);
          }}
        />
        <div>Усього: {OfficialDoc.docsTotal || OfficialDoc?.searchData?.total}</div>
        {OfficialDoc.loading || loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDoc.searchData?.officialDoc?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell >{row.title}</TableCell>
                  <TableCell  align='center'>
                    {row.type}
                  </TableCell>
                  <TableCell  align='center' style={{ color: colorsStatus[row.approved] }}>
                    {OfficialDoc.status.approved[row.approved]}
                  </TableCell>
                  <TableCell  align='center'>
                    {moment(row.update).format('DD.MM.YYYY HH:mm')}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      aria-label='edit'
                      onClick={() => {
                        window.open(`/document/${row.id}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'edit' }));
                        dispatch(GetRedactions.request({ data: { groupId: row.groupId } }));
                        setGroupId(row.groupId);
                      }}
                    >
                      Переглянути
                    </Button>
                  </TableCell>
                  <TableCell >
                    {row.creatorFio || ''}
                    <br />
                    {row.authorFio || ''}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
