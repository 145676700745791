import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface EditorProps {
  editorRef?: React.MutableRefObject<any | null>;
  initialValue?: string;
  placeholder?: string;
}

export const EditorDefault: React.FC<EditorProps> = ({ editorRef, placeholder, initialValue }) => {
  return (
    <>
      {placeholder && (
        <h3
          style={{
            margin: '0 0 5px',
            fontWeight: 400,
            lineHeight: '1.334',
            letterSpacing: '0em',
          }}
        >
          {placeholder}
        </h3>
      )}
      <Editor
        onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
        apiKey="uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv"
        initialValue={initialValue}
      //   init={{
      //     convert_urls: true,
      //     link_context_toolbar: true,
      //     target_list: [
      //       {title: 'Нова сторінка', value: '_blank'},
      //       {title: 'Та сама сторінка', value: '_self'},
      //     ],
      //     link_default_protocol: 'https',
      //     urlconverter_callback: function (url, node, on_save, name) {
      //       const regexName = /\b(\w*\.expertus\.com\.ua)\/law\w*\b/g;
      //       const regexInt = /^\d+/g;
      //       if (url.match(regexName)) {
      //         // eslint-disable-next-line
      //         url = url.replace(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?/gm, '');
      //         url = url.replace(/\/laws\//gm, '/law/');
      //       } else if (url.match(regexInt)) {
      //         url = `/law/${url}`
      //       }

      //       return url;
      //     },
      //     language: 'uk',
      //     height: 150,
      //     width: '900px',
      //     plugins: 'paste preview advcode link charmap',
      //     menubar: false,
      //     toolbar:
      //         'undo redo | code | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | forecolor backcolor | charmap | link',
      //     paste_as_text: true,
      //     quickbars_selection_toolbar: false,
      //     content_style: 'body { font-size: 14px; }',
      //     placeholder
      // }}
      />
    </>
  );
};
