import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { IBlock } from '../../store/blocks/types';
import {
  UpdateBlock,
  CreateBlock,
  DeleteSubBlock,
  SearchFreeIds,
  ResetItemId,
  GetFreeIds,
} from '../../store/blocks/actions';
import { useSelectedBlocks } from '../../hooks/useSelectedBlocks';
import { ManageBlocks } from '../ManageBlocks/ManageBlocks';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';

interface PropsType {
  type: 'edit' | 'new';
  data?: IBlock | null;
  handleCancel: () => void;
}

export const EditBlock: React.FC<PropsType> = ({ type, data, handleCancel }) => {
  const [name, setName] = React.useState<string>(data?.name || '');
  const [error, setError] = React.useState(false);

  const { Blocks, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  const { subBlocks, options, setSubBlocks, handleChange, handleSearch } = useSelectedBlocks({
    DeleteData: DeleteSubBlock,
    SearchFreeIds: SearchFreeIds,
    ResetItemId: ResetItemId,
    blocks: Blocks.blocks,
    currentId: data?.id,
    ids: Blocks.titleIdsPairs,
  });

  React.useEffect(() => {
    dispatch(
      GetFreeIds.request({
        siteId: Configurations.siteId,
      })
    );
  }, [dispatch, Configurations.siteId]);

  const onSubmit = () => {
    const callBack = (value: boolean) => {
      if (value) {
        handleCancel();
      }
    };

    if (!name || !subBlocks.length || subBlocks.length === 0) return setError(true);

    const blocks = subBlocks;

    let subBlockErorr = false;

    blocks.forEach((block) => {
      if (!block.itemId || !block.title) subBlockErorr = true;
    });

    if (subBlockErorr) return setError(true);


    setError(false);
    if (type === 'new') {
      dispatch(
        CreateBlock.request({
          data: {
            siteId: Configurations.siteId,
            name,
            subBlocks: blocks.map((it, i) => ({ ...it, position: i, anchor: it.anchor?.replace('#', '') })),
          },
          callBack,
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateBlock.request({
          data: {
            name,
            subBlocks: blocks.map((it, i) => ({ ...it, position: i, anchor: it.anchor?.replace('#', '') })),
          },
          id: data.id,
          callBack,
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div>
        <Typography component='h1' variant='h5'>
          {type === 'new' ? 'Створити блок' : 'Редагувати блок'}
        </Typography>
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Назва'
          variant='outlined'
          value={name}
          error={error && !name}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setName(event.target.value as string);
          }}
        />
        <ManageBlocks
          blocks={subBlocks}
          error={error}
          options={options}
          setSubBlocks={setSubBlocks}
          handleChange={handleChange}
          handleSearch={handleSearch}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Blocks.loading} onClick={onSubmit}>
            {Blocks.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {Blocks.errors ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
