import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';
import { TypeOptions } from '../ui/SelectPopup';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';

interface RubricsProps {
  ids: { [x: string]: TypeOptions };
  level: number;
  option: TypeOptions;
  chain: string;
  handlePin: (value: TypeOptions) => void;
  handleUnpin: (value: TypeOptions) => void;
}

const Rubrics: React.FC<RubricsProps> = ({ ids, option, level, chain, handlePin, handleUnpin }) => {
  const [selected, setSelected] = React.useState('');

  const handleChange = (item: TypeOptions, checked: boolean) => {
    if (checked) {
      handlePin(item);
    } else {
      handleUnpin(item);
    }
  };

  return (
    <div>
      <div >
        <div  onClick={() => setSelected(chain === selected ? '' : chain)}>
          {option.children && option.children.length > 0 ? (
            selected === chain ? (
              <RemoveIcon style={{ fontSize: '1rem' }} />
            ) : (
              <AddIcon style={{ fontSize: '1rem' }} />
            )
          ) : (
            ''
          )}
        </div>
        <div >
          <div >{option.name}</div>
          {!option.children ? (
            <FormControlLabel
              style={{ margin: '0' }}
              control={<Checkbox checked={!!ids[option.id]} />}
              onChange={(ev, checked) => handleChange({ id: option.id, name: option.name }, checked)}
              label=''
            />
          ) : null}
        </div>
      </div>

      {selected === chain && (
        <div >
          {option.children?.map((it, i) => (
            <Rubrics
              key={i}
              ids={ids}
              option={it}
              level={level + 1}
              chain={`${chain}-${i}`}
              handlePin={handlePin}
              handleUnpin={handleUnpin}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface Props {
  values?: TypeOptions[];
  open: boolean;
  handleApprove: (approved: boolean, value: TypeOptions[]) => void;
  options?: TypeOptions[] | null;
}

export const RubricsModal: React.FC<Props> = ({ values, open, options, handleApprove }) => {
  const [ids, setIds] = React.useState<{ [x: string]: TypeOptions }>({});

  React.useEffect(() => {
    if (values) {
      const obj = values.reduce((acum, val) => {
        acum[val.id] = val;
        return acum;
      }, {} as { [x: string]: TypeOptions });
      setIds(obj);
    }
  }, [values]);

  const handlePin = (value: TypeOptions) => {
    setIds((prev) => {
      const obj = { ...prev };
      obj[value.id] = value;
      return obj;
    });
  };

  const handleUnpin = (value: TypeOptions) => {
    setIds((prev) => {
      const obj = { ...prev };
      delete obj[value.id];
      return obj;
    });
  };

  const handleClose = (approved: boolean) => {
    handleApprove(
      approved,
      Object.keys(ids).map((key) => ids[key])
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className='boxModal-inner' style={{ minWidth: '60%', maxHeight: '90%', overflow: 'auto' }}>
        <div className='boxBody' style={{ textAlign: 'center' }}>
          <h2>Рубрикатор</h2>
        </div>
        <div style={{ padding: '10px 20px' }}>
          {options?.map((it, i) => (
            <Rubrics
              key={i}
              ids={ids}
              option={it}
              level={1}
              chain={String(i)}
              handlePin={handlePin}
              handleUnpin={handleUnpin}
            />
          ))}
        </div>
        <div className='boxFooter'>
          <div style={{ display: 'flex' }}>
            <Button color='primary' variant='contained' onClick={() => handleClose(true)}>
              Зберегти
            </Button>
          </div>
          <Button variant='contained' onClick={() => handleClose(false)}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
