import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import {
  UpdateBlock,
  CreateBlock,
  DeleteSubBlock,
  SearchFreeIds,
  ResetItemId,
  GetFreeIds,
} from '../../store/main/actions';
import { useSelectedMain } from '../../hooks/useSelectedMain';
import { IMain } from '../../store/main/types';
import { ManageBlocks } from '../ManageBlocks/ManageBlocks';
import { BasicDatePicker } from '../ui';

interface PropsType {
  type: 'edit' | 'new';
  data?: IMain | null;
  handleCancel: () => void;
}

export const EditMainBlock: React.FC<PropsType> = ({ type, data, handleCancel }) => {
  const [date, setDate] = React.useState<Date | null>(data?.setdate || new Date());
  const [error, setError] = React.useState(false);

  const { Main, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  const { options, subBlocks, handleSearch, handleChange, setSubBlocks } = useSelectedMain({
    DeleteData: DeleteSubBlock,
    SearchFreeIds: SearchFreeIds,
    ResetItemId: ResetItemId,
    blocks: Main.data?.blocks,
    currentId: data?.id,
    ids: Main.titleIdsPairs,
  });

  React.useEffect(() => {
    dispatch(
      GetFreeIds.request({
        siteId: Configurations.siteId,
      })
    );
  }, [dispatch, Configurations.siteId]);

  const onSubmit = () => {
    const callBack = (value: boolean) => {
      if (value) {
        handleCancel();
      }
    };

    if (!date || !subBlocks || subBlocks.length === 0) return setError(true);
    // const keys = Object.keys(subBlocks[0]);
    // const blocks = subBlocks.filter((it: any) => {
    //   if (it.blockId === data?.id || it.blockId === 0) {
    //     return keys.filter((key) => !!it[key]).length >= 5;
    //   }
    //   return false;
    // });
    const blocks = subBlocks;

    setError(false);
    if (type === 'new') {
      dispatch(
        CreateBlock.request({
          data: {
            siteId: Configurations.siteId,
            setdate: date,
            subBlocks: blocks.map((it, i) => ({ ...it, position: i,anchor:it.anchor?.replace('#','') })),
          },
          callBack,
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateBlock.request({
          data: {
            setdate: date,
            subBlocks: blocks.map((it, i) => ({ ...it, position: i ,anchor:it.anchor?.replace('#','') })),
          },
          id: data.id,
          callBack,
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div >
        <Typography component='h1' variant='h5'>
          {type === 'new' ? 'Створити блок' : 'Редагувати блок'}
        </Typography>
        <div style={{ width: '214px', marginTop: '20px' }}>
          <BasicDatePicker label='Дата' value={date} onChange={setDate} />
        </div>
        <ManageBlocks
          type='main'
          blocks={subBlocks}
          error={error}
          options={options}
          setSubBlocks={setSubBlocks}
          handleChange={handleChange}
          handleSearch={handleSearch}
        />{' '}
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Main.loading} onClick={onSubmit}>
            {Main.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {Main.errors ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
