import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { debounce } from '../../utils/debounce';
import { TIMEOUT_REQ } from '../../utils/consts';

export interface IDataOpt {
  id: number;
  label: string;
  title?: string;
}

type PropsType = {
  title?: string;
  value?: string;
  data?: IDataOpt | null;
  required?: boolean;
  options?: IDataOpt[];
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  onChange: (data: IDataOpt | null) => void;
  handleRequest?: (value: string | IDataOpt, callback?: (data: IDataOpt[]) => void) => void;
  handleOutside?: () => void;
};

export const SelectSearchReq: React.FC<PropsType> = React.memo(
  ({ style, value, className, title, required, error, data, options, onChange, handleRequest, handleOutside }) => {
    const [search, setSearch] = React.useState(value);
    const [bodyValue, setBodyValue] = React.useState(value);
    const [items, setItems] = React.useState<IDataOpt[] | undefined>([]);
    const [isFocus, setIsFocus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const { ref } = useOutsideClick(() => setIsFocus(false));

    // eslint-disable-next-line
    const debounceSave = React.useCallback(
      debounce((data) => {
        setLoading(false);
        if (handleRequest) {
          handleRequest(data, (resData: IDataOpt[]) => {
            setItems(resData);
          });
        }
      }, TIMEOUT_REQ),
      [handleRequest]
    );

    React.useEffect(() => {
      if (options) {
        setItems(options);
      }
    }, [options]);

    React.useEffect(() => {
      if ((!data && !value) || (!data && !value && !isFocus)) {
        setSearch('');
        setBodyValue('');
      }
      if (data && !value) {
        setSearch(data.label);
        setBodyValue(data.label);
      }
    }, [data, value, isFocus]);

    React.useEffect(() => {
      if (handleOutside && !isFocus) {
        handleOutside();
      }
    }, [handleOutside, isFocus]);

    const handleLeaveFocus = () => {
      setIsFocus(!isFocus);
    };

    // const setClass = () => {
    //   if (isFocus) return 'classes.placeholderFocus';
    //   if (!!bodyValue) return 'classes.placeholderInit';
    // };

    const handleSearch = (search: string) => {
      setSearch(search);
      setLoading(true);
      debounceSave(search);
      if (!search) {
        onChange(null);
        setBodyValue(search);
      }
    };

    const handleChoice = (option: IDataOpt) => {
      setSearch(option.label);
      setBodyValue(option.label);
      onChange(option);
      setIsFocus(false);
    };

    return (
      <div style={style}  ref={ref}>
        {title && (
          <div style={{ color: error && !isFocus ? 'red' : '' }}>
            {required ? `${title} *` : title}
          </div>
        )}
        <div
          style={{ borderColor: error && !isFocus ? 'red' : '' }}
          onClick={() => setIsFocus(true)}
        >
          <div >
            {isFocus ? (
              <input
                autoFocus
                
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
              />
            ) : (
              bodyValue
            )}
          </div>
          <div onClick={handleLeaveFocus}>
            {loading ? <CircularProgress size={15} /> : <ArrowDropDownIcon />}
          </div>
        </div>
        {isFocus ? (
          <div >
            <MenuList>
              {items?.map((option) => (
                <MenuItem key={Math.random()} value={option.id} onClick={() => handleChoice(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </div>
        ) : null}
      </div>
    );
  }
);
