import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DeleteBlock, GetMainBlocks, UpdateBlock } from '../../store/main/actions';
import { EditMainBlock } from './EditMainBlock';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { IMain } from '../../store/main/types';
import Pagination from '@mui/material/Pagination';
import { ROW_PAGE } from '../../utils/consts';
import { WarningModal } from '../modals';

interface Column {
  id: 'id' | 'name' | 'adate' | 'isPublic' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '3%' },
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },
  { id: 'adate', label: 'Створено', align: 'left', width: '10%' },
  { id: 'isPublic', label: 'Публікція', align: 'left', width: '10%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListMainBlocks: React.FC<PropsType> = () => {
  const [page, setPage] = React.useState(1);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: IMain | null } | null>(null);
  const { Main, Configurations, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      setEditData(null);
    }
  }, [Sidebar.page]);

  const handleRemove = (id: number) => {
    dispatch(DeleteBlock.request({ id }));
  };

  const onPublic = (id: number, value: number) => {
    dispatch(
      UpdateBlock.request({
        data: {
          isPublic: value ? 0 : 1,
        },
        id,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    handleGetMore(newPage);
  };

  const handleGetMore = (page: number) => {
    if (!Main.data || !Main.data.blocks) return null;
    const limit = Math.round(page / 10) * 100 + 200;
    if (limit > Main.data.blocks.length && Main.data.blocks.length !== Main.data.total) {
      dispatch(
        GetMainBlocks.request({
          id: Configurations.siteId,
          limit,
        })
      );
    }
  };

  if (editData) {
    return <EditMainBlock type={editData.type} data={editData.data} handleCancel={() => setEditData(null)} />;
  }

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <Box>
          <Button
            style={{ margin: '0 0 10px 0' }}
            variant='contained'
            color='primary'
            onClick={() => {
              setEditData({ type: 'new' });
            }}
          >
            Створити блок
          </Button>
        </Box>
      </Box>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Main.data &&
              Main.data.blocks?.slice((page - 1) * ROW_PAGE, page * ROW_PAGE).map((row, i) => {
                return (
                  <TableRow key={`${i}-${row.id}`}>
                    <TableCell align='center'>{row.id}</TableCell>
                    <TableCell >{moment(row.setdate).format('DD.MM.YYYY HH:mm')}</TableCell>
                    <TableCell >{moment(row.adate).format('DD.MM.YYYY HH:mm')}</TableCell>
                    <TableCell >
                      <IconButton aria-label='edit' onClick={() => onPublic(row.id, row.isPublic)}>
                        {row.isPublic ? <PlayArrowIcon /> : <PauseIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton aria-label='edit' onClick={() => setEditData({ type: 'edit', data: row })}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        
        count={Main.data ? Math.ceil(Main.data.total / ROW_PAGE) : 0}
        page={page}
        onChange={handleChangePage}
      />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
