import * as React from 'react';
import ukLocale from 'date-fns/locale/uk';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CalendarPickerView } from '@mui/lab';

interface PropsType {
  id?: string;
  label?: string;
  value: Date | null;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  views?: CalendarPickerView[];
  style?: React.CSSProperties | undefined;
  onChange: (value: Date | null) => void;
  format?: string;
}

export const BasicDatePicker: React.FC<PropsType> = ({
  value,
  onChange,
  label,
  views,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
      <DatePicker
        label={label}
        views={Array.isArray(views) && views?.length ? views : ['year', 'month', 'day']}
        // value={value}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
};
